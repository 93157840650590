/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Custom color theme properties */
:root {
  --f7-theme-color: #dcab32;
  --f7-theme-color-rgb: 220, 171, 50;
  --f7-theme-color-shade: #c49521;
  --f7-theme-color-tint: #e2b955;
}

/* Your app custom styles here */

.shine-location-page .page-content {
}

.toolbar.location {
    background: #333333 !important;
    height: 80px;
    backdrop-filter: none;
}